import { SubscriptionStatusEnum } from "views/admin/subscription/enum/subscription.enum";
import { UserSubscriptionStatus } from "views/auth/interfaces/user.interface";

export const subscriptionStatusColorMapper = {
  [SubscriptionStatusEnum.ACTIVE]: "green.400",
  [UserSubscriptionStatus.ACTIVE]: "green.400",
  [SubscriptionStatusEnum.CANCELLED]: "yellow.400",
  [SubscriptionStatusEnum.EXPIRED]: "red.400",
  [UserSubscriptionStatus.IN_TRIAL]: "green.400",
  [SubscriptionStatusEnum.ON_TRIAL]: "green.400",
  [UserSubscriptionStatus.TRIAL_EXPIRED]: "red.400",
  [SubscriptionStatusEnum.PAST_DUE]: "yellow.400",
  [SubscriptionStatusEnum.PAUSED]: "yellow.400",
  [SubscriptionStatusEnum.UNPAID]: "red.400",
};

export const subscriptionStatusTextMapper = {
  [SubscriptionStatusEnum.ACTIVE]: "Active",
  [SubscriptionStatusEnum.CANCELLED]: "Cancelled",
  [SubscriptionStatusEnum.EXPIRED]: "Expired",
  [UserSubscriptionStatus.IN_TRIAL]: "On Trial",
  [SubscriptionStatusEnum.ON_TRIAL]: "On Trial",
  [UserSubscriptionStatus.TRIAL_EXPIRED]: "Trial Expired",
  [UserSubscriptionStatus.ACTIVE]: "Active",
  [SubscriptionStatusEnum.PAST_DUE]: "Past Due",
  [SubscriptionStatusEnum.PAUSED]: "Paused",
  [SubscriptionStatusEnum.UNPAID]: "Unpaid",
};
