import MainLoader from "components/mainLoader/MainLoader";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { Route, Switch } from "react-router-dom";
import { useAuthStore } from "views/auth/store/auth.store";

function AuthProvider() {
  const isSessionLoaded = useAuthStore((state) => state.isSessionLoaded);

  if (!isSessionLoaded) {
    return <MainLoader />;
  }

  return (
    <Switch>
      <Route path={`/auth`} component={AuthLayout} />
      <Route path={`/`} component={AdminLayout} />
    </Switch>
  );
}

export default AuthProvider;
