import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import illustration from "assets/img/auth/auth-bg.png";
import DefaultAuth from "layouts/auth/Default";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { updatePassword } from "../utils/auth.util";

function UpdatePassword() {
  const history = useHistory();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [isLoading, setIsLoading] = useState(false);
  const [pwdShow, setPwdShow] = useState(false);
  const handleClick = () => setPwdShow(!pwdShow);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<{ password: string }>();

  async function onSubmit(data: { password: string }) {
    try {
      setIsLoading(true);
      const user = await updatePassword(data?.password);
      if (user?.id) {
        toast("Password updated successfully", { type: "success" });
        history.replace("/");
      } else {
        toast("Something went wrong", { type: "error" });
      }
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      reset();
      toast(error?.message, { type: "error" });
    }
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your new password to get login to your account!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              isInvalid={Boolean(errors?.password?.message)}
              mb="24px"
            >
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                New Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  fontSize="sm"
                  placeholder="Enter password"
                  size="lg"
                  type={pwdShow ? "text" : "password"}
                  variant="auth"
                  {...register("password", {
                    required: "Password required. No password, no entry! 🚧",
                  })}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={pwdShow ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              type="submit"
              isLoading={isLoading}
              loadingText="Updating"
            >
              Update
            </Button>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default UpdatePassword;
