import { axiosInstance } from "utils/axios.util";
import { IProfile } from "../interface/profile.interface";

export async function getAllProfile() {
  const { data } = await axiosInstance({
    method: "GET",
    url: `/profile`,
  });
  return data as IProfile[];
}
