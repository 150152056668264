import { useQuery } from "react-query";
import { getAllProfile } from "../api/profile.api";

export enum ProfileQueryEnum {
  GET_ALL_PROFILE = "get-all-profile",
}

export const useGetAllProfileQuery = () => {
  const { data, isLoading } = useQuery({
    queryKey: ProfileQueryEnum.GET_ALL_PROFILE,
    queryFn: getAllProfile,
  });
  return { data, isLoading };
};
