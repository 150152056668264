import { Box, Flex, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { envConfig } from "config/env.config";
import { tw } from "twind";

function DownloadExtension() {
  return (
    <Box pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
      <Flex gap={10} justifyContent="center" wrap="wrap">
        <Box maxW={{ lg: "850px" }} m="auto">
          <Card
            gap={5}
            p={10}
            fontWeight={500}
            mt={5}
            display="flex"
            justifyContent="center"
          >
            <Text textAlign="center" fontSize="lg" fontWeight="bold">
              Download our chrome extension
            </Text>
            <Text textAlign="center">
              Effortlessly connect your LinkedIn profile directly to our
              platform with zero effort on your part.
            </Text>
            <Text textAlign="center" fontSize="md">
              Click{" "}
              <a
                href={envConfig.extensionUrl}
                className={tw(`text-[#422AFB]`)}
                target="_blank"
              >
                here{" "}
              </a>
              to download.
            </Text>
          </Card>
        </Box>
      </Flex>
    </Box>
  );
}

export default DownloadExtension;
