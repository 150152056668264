import { useQuery } from "react-query";
import { getUser } from "../api/user.api";

export enum UserQueryEnum {
  GET_USER = "get-user",
}

export const useGetUserQuery = () => {
  const { data, isLoading, isFetching, isFetched } = useQuery({
    queryKey: UserQueryEnum.GET_USER,
    queryFn: getUser,
    placeholderData: null,
    retry: 3,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 1000 * 60 * 5,
  });
  return { data, isLoading, isFetching, isFetched };
};
