import {
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { envConfig } from "config/env.config";
import { useMemo } from "react";
import { LuSettings2, LuLink } from "react-icons/lu";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { QueryService } from "services/query.service";
import { tw } from "twind";
import { LINK_LI_ACCOUNT } from "../constants/browserEvents.constant";
import { ProfileStatusEnum } from "../enum/profile.enum";
import { IProfile } from "../interface/profile.interface";
import { ProfileQueryEnum } from "../query/profile.query";

function ProfileCard({
  firstName,
  lastName,
  status,
  publicIdentifier,
  setting,
  _id,
}: IProfile) {
  const queryClient = QueryService.getQueryClient();
  const cardHoverShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const cardShadow = useColorModeValue(
    "0px 6px 15px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  const iconBgColor = useColorModeValue("brand.500", "blue");
  const iconBgHoverColor = useColorModeValue("brand.300", "blue");
  const history = useHistory();

  const getProfileStatus = () => {
    if (!setting || (setting && !Object.keys(setting).length))
      return "Not Activated";
    else if (status === ProfileStatusEnum.ACTION_REQUIRED)
      return "Disconnected";
    else if (status === ProfileStatusEnum.OK) return "Connected";
    return "No Status";
  };

  const shouldShowLinkButton = useMemo(() => {
    const profileStatus = getProfileStatus();
    if (profileStatus === "Disconnected") return true;
    return false;
  }, [status, setting]);

  const getProfileStatusTextColor = () => {
    if (!setting) return "red.400";
    else if (status === ProfileStatusEnum.ACTION_REQUIRED) return "red.400";
    else if (status === ProfileStatusEnum.OK) return "green.400";
    return "red.400";
  };

  const linkLinkedInProfileFromExtension = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(
        envConfig.chromeExtensionId,
        { type: LINK_LI_ACCOUNT },
        (response) => {
          if (chrome.runtime.lastError) {
            reject(chrome.runtime.lastError.message);
          } else {
            resolve(response);
          }
        }
      );
    });
  };

  const handleResponseFromExtension = async () => {
    try {
      const response = await linkLinkedInProfileFromExtension();
      if (response?.profileUrn) {
        toast("Connected", { type: "success" });
        queryClient.invalidateQueries(ProfileQueryEnum.GET_ALL_PROFILE);
      } else if (response?.errorCode && response?.message) {
        toast(response.message, { type: "error" });
      } else if (response?.message) {
        toast(response.message, { type: "info" });
      }
    } catch (error: any) {
      toast(error ?? error?.message ?? "Something went wrong while connected", {
        type: "error",
      });
      window.open("https://www.linkedin.com", "_blank");
    }
  };

  const handleCardOnclick = async () => {
    if (shouldShowLinkButton) {
      await handleResponseFromExtension();
    } else history.push(`/profile/${_id}/setting`);
  };

  return (
    <Tooltip
      label={
        shouldShowLinkButton
          ? "Connect your LinkedIn profile"
          : "Go to keyword settings"
      }
    >
      <Card
        bg={bg}
        p={6}
        minW="250px"
        maxW="300px"
        boxShadow={cardShadow}
        className={tw(`transition-all ease-in-out hover:cursor-pointer`)}
        _hover={{ boxShadow: cardHoverShadow }}
        onClick={handleCardOnclick}
      >
        <Box w="full">
          <Box mt={{ base: "10px", md: "0" }}>
            <Text color={textColorPrimary} fontWeight="500" fontSize="md">
              {`${firstName} ${lastName}`}
            </Text>
            <Text
              fontWeight="500"
              color={textColorSecondary}
              fontSize="sm"
              me="4px"
              mb="4px"
            >
              {`@${publicIdentifier}`}
            </Text>
            <Text
              fontWeight="500"
              color={getProfileStatusTextColor()}
              fontSize="sm"
              me="4px"
            >
              {getProfileStatus()}
            </Text>
            <Flex justifyContent="flex-end" alignItems="center" w="full">
              <IconButton
                aria-label="Settings"
                icon={shouldShowLinkButton ? <LuLink /> : <LuSettings2 />}
                size="sm"
                bgColor={iconBgColor}
                color="white"
                _hover={{ backgroundColor: iconBgHoverColor }}
              />
            </Flex>
          </Box>
        </Box>
      </Card>
    </Tooltip>
  );
}

export default ProfileCard;
