import { Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import { useCheckRedemptionCode } from "api-services/redemption/redemption.query";
import queryString from "query-string";
import { ReactNode, useEffect, useMemo } from "react";
import { MdOutlineError } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { tw } from "twind";
import { useLocalStorage } from "react-use";
import { REDEMPTION_CODE_KEY } from "api-services/redemption/redemption.constant";

function RedemptionCheckComponent({
  redemptionCode,
  children,
}: {
  redemptionCode: string;
  children: ReactNode;
}) {
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [, storeRedemptionCode] = useLocalStorage(REDEMPTION_CODE_KEY);

  const { data, isLoading } = useCheckRedemptionCode({
    code: redemptionCode ?? "",
  });

  useEffect(() => {
    if (data && !data?.isClaimed) storeRedemptionCode(redemptionCode);
  }, [data]);

  if (redemptionCode && isLoading) {
    return (
      <div className={tw(`flex flex-col items-center gap-8`)}>
        <Spinner color="brand.500" size="xl" />
        <p className={tw(`text-xl font-medium`)}>
          Verifying Your Redemption Code
        </p>
      </div>
    );
  } else if (redemptionCode && !data) {
    return (
      <div className={tw(`flex flex-col items-center gap-5`)}>
        <MdOutlineError size="50px" color="#EE5D50" />
        <p className={tw(`text-xl font-medium`)}>Invalid Redemption Code</p>
        <Text color={textColorDetails} fontWeight="400" fontSize="14px">
          <NavLink to="/auth/sign-up">
            <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
              Sign up
            </Text>
          </NavLink>
          &nbsp;without redemption code
        </Text>
      </div>
    );
  } else if (redemptionCode && data?.isClaimed) {
    return (
      <div className={tw(`flex flex-col items-center gap-5`)}>
        <MdOutlineError size="50px" color="#EE5D50" />
        <p className={tw(`text-xl font-medium`)}>Code Already Redeemed</p>
        <Text color={textColorDetails} fontWeight="400" fontSize="14px">
          <NavLink to="/auth/sign-up">
            <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
              Sign up
            </Text>
          </NavLink>
          &nbsp;without redemption code
        </Text>
      </div>
    );
  }
  return <>{children}</>;
}

function RedemptionCheckProvider({ children }: { children: ReactNode }) {
  const { search } = useLocation();
  const queries = queryString.parse(search);
  const redemptionCode = useMemo(() => {
    return queries?.redemptionCode as string | undefined;
  }, [queries]);

  if (redemptionCode)
    return (
      <RedemptionCheckComponent redemptionCode={redemptionCode}>
        {children}
      </RedemptionCheckComponent>
    );
  return <>{children}</>;
}

export default RedemptionCheckProvider;
