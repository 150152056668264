import { REDEMPTION_CODE_KEY } from "api-services/redemption/redemption.constant";
import { useClaimRedemption as useClaimRedemptionQuery } from "api-services/redemption/redemption.query";
import { useEffect } from "react";
import { useLocalStorage } from "react-use";

function useClaimRedemption() {
  const [redemptionCode, , removeRedemptionCode] =
    useLocalStorage(REDEMPTION_CODE_KEY);
  const { claim } = useClaimRedemptionQuery({
    removeCode: removeRedemptionCode,
  });

  useEffect(() => {
    if (redemptionCode) claim({ redemptionCode: redemptionCode as string });
  }, [redemptionCode]);
}

export default useClaimRedemption;
