import { useInfiniteQuery, useQuery } from "react-query";
import { getCompletedPosts } from "../api/post.api";

export enum PostQueryEnum {
  GET_COMPLETED_POSTS = "get-completed-posts",
}

export const useGetCompletedPostsQuery = () => {
  const {
    data,
    isLoading,
    isFetching,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: PostQueryEnum.GET_COMPLETED_POSTS,
    queryFn: async ({ pageParam = 0 }) => {
      const response = await getCompletedPosts(pageParam);
      return response;
    },
    placeholderData: null,
    retry: 1,
    getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
  });

  return {
    data,
    isLoading,
    isFetching,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
