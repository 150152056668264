export const planSetting = {
  numberOfPostsToScrapePerDay: {
    starter: 20,
    pro: 50,
    premium: 100,
  },
  tagAuthor: {
    starter: false,
    pro: true,
    premium: true,
  },
};
