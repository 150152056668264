import { envConfig } from "config/env.config";
import { useEffect } from "react";
import { useGetUserQuery } from "views/auth/query/user.query";
import { OpenReplayInstance } from "views/auth/services/open-replay.service";

function useSessionReplay() {
  const { data: user } = useGetUserQuery();
  const isProd = envConfig.environment === "production";
  const tracker = OpenReplayInstance.getInstance(envConfig.openReplayProjectId);

  useEffect(() => {
    if (user?.email && isProd) {
      const { _id, email, firstName } = user;
      tracker.start({
        userID: email,
        metadata: {
          name: firstName,
          id: _id,
          email,
        },
      });
    }
    return () => {
      tracker.stop();
    };
  }, [user]);
}

export default useSessionReplay;
