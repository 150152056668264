import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { QueryService } from "services/query.service";
import { CreditsQueryEnum } from "views/admin/credits/query/credits.query";
import {
  checkIsLifetimeActivated,
  createTransaction,
  getPlans,
} from "../api/pricing.api";

export enum TransactionQueryEnum {
  GET_TRANSACTION_BY_ID = "get-transaction-by-id",
  GET_CHECK_IS_LIFETIME = "check-is-lifetime",
}

export enum PlanQueryEnum {
  GET_PLANS = "get-plans",
}

export const useCreateTransactionQuery = ({ history }: { history: any }) => {
  const queryClient = QueryService.getQueryClient();
  const { mutate, isLoading } = useMutation(createTransaction, {
    onSuccess: () => {
      queryClient.invalidateQueries(CreditsQueryEnum.GET_CREDITS);
      history.push(`/`);
    },
    onError: (error: any) => {
      toast(error.message || "Something went wrong while transacting", {
        type: "error",
      });
    },
  });

  return { createTransaction: mutate, isCreatingTransaction: isLoading };
};

export const useGetCheckLifetimeQuery = () => {
  const { data, isLoading } = useQuery({
    queryKey: TransactionQueryEnum.GET_CHECK_IS_LIFETIME,
    queryFn: () => checkIsLifetimeActivated(),
    retry: 3,
    refetchOnWindowFocus: false,
  });
  return { data, isLoading };
};

export const useGetPlans = () => {
  const { data, isLoading } = useQuery({
    queryKey: PlanQueryEnum.GET_PLANS,
    queryFn: () => getPlans(),
    retry: 2,
    refetchOnMount: true,
    staleTime: 1000 * 60 * 60,
  });
  return { data, isLoading };
};
