import { Box, Flex, Spinner, Stack, Text, VStack } from "@chakra-ui/react";
import Card from "components/card/Card";
import SwitchField from "components/fields/SwitchField";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { tw } from "twind";
import { UserSubscriptionStatus } from "views/auth/interfaces/user.interface";
import { useGetUserQuery } from "views/auth/query/user.query";
import {
  useCreateCheckoutUrl,
  useUpdateSubscriptionPlan,
} from "../subscription/query/subscription.query";
import PricingCell from "./components/PricingCell";
import { IDisplayProduct } from "./interfaces/price.interface";
import { useGetPlans } from "./query/pricing.query";

export default function Pricing() {
  const { data: user } = useGetUserQuery();
  const { data: plans, isLoading: isFetchingPlans } = useGetPlans();
  const history = useHistory();
  const { updateSubscriptionPlan, isUpdatingSubscriptionPlan } =
    useUpdateSubscriptionPlan({ history });
  const popularPlans = ["pro"];
  const [subscriptionType, setSubscriptionType] = useState<
    "monthly" | "yearly"
  >("monthly");

  const handleOpenLsModal = (url: string) => {
    try {
      window.LemonSqueezy.Url.Open(url);
    } catch (error) {
      toast("Checkout url is not valid");
    }
  };

  const { createCheckoutUrl, isCreatingCheckoutUrl } = useCreateCheckoutUrl({
    cb: handleOpenLsModal,
  });

  const updatedPlans: IDisplayProduct[] = useMemo(() => {
    return plans?.map((plan) => {
      const variant = plan.variant.find(
        (v) => v.name.toLowerCase() === subscriptionType
      );
      const updatedVariant = {
        ...variant,
        displayPrice: `$${Math.round(variant?.price / 100)}`,
      };
      if (popularPlans.includes(plan.name.toLowerCase())) {
        return {
          ...plan,
          popular: true,
          variant: updatedVariant,
        };
      }
      return {
        ...plan,
        variant: updatedVariant,
      };
    });
  }, [plans, subscriptionType]);

  const handleUpdatingSubscription = () => {
    setSubscriptionType((prev) => {
      if (prev === "monthly") return "yearly";
      return "monthly";
    });
  };

  const getDisabledPlanState = (data: IDisplayProduct): boolean => {
    if (user?.status === UserSubscriptionStatus.IN_TRIAL) return false;
    else if (user?.subscribedProductVariantId === data?.variant?._id)
      return true;
    return false;
  };

  const handlePlanSelect = ({
    productId,
    variantId,
  }: {
    variantId: string;
    productId: string;
  }) => {
    if (user?.subscription) {
      updateSubscriptionPlan({ productId, variantId });
    } else {
      createCheckoutUrl({ variantId, embed: false });
    }
  };

  if (isFetchingPlans)
    return (
      <Box pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
        <Box maxW={{ lg: "850px" }} m="auto">
          <Card
            gap={5}
            p={5}
            fontWeight={500}
            mt={5}
            display="flex"
            justifyContent="center"
          >
            <Text textAlign="center" fontSize="lg" fontWeight="bold">
              Hold tight! Plan's en route! 🚀
            </Text>
            <Flex justifyContent="center">
              <Spinner color="brand.500" size="md" />
            </Flex>
          </Card>
        </Box>
      </Box>
    );

  return (
    <Box py={12} pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
      <VStack
        spacing={2}
        textAlign="center"
        justifyContent="center"
        alignItems="center"
      >
        <Text as="h1" fontSize="4xl" fontWeight="bold">
          Plans that fit your need
        </Text>
        <Text fontSize="lg" color={"gray.500"}>
          Simple price, Big impact
        </Text>
        <SwitchField
          reversed={true}
          fontSize="sm"
          mb={4}
          id="1"
          label={subscriptionType}
          className={tw(`capitalize w-max! mt-4!`)}
          onChange={handleUpdatingSubscription}
        />
      </VStack>

      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        {updatedPlans?.map((data, idx) => (
          <PricingCell
            key={idx}
            onClick={() =>
              handlePlanSelect({
                variantId: data?.variant?._id,
                productId: data?._id,
              })
            }
            disabled={
              isCreatingCheckoutUrl ||
              isUpdatingSubscriptionPlan ||
              getDisabledPlanState(data)
            }
            isSubmitLoading={
              isCreatingCheckoutUrl || isUpdatingSubscriptionPlan
            }
            {...data}
          />
        ))}
      </Stack>
    </Box>
  );
}
