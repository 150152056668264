import { Icon } from "@chakra-ui/react";
import { MdLock } from "react-icons/md";
import { FaBookOpen, FaLinkedin } from "react-icons/fa";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";

// Auth Imports
import ForgotPassword from "views/auth/forgot-password";
import SignIn from "views/auth/sign-in";
import SignUp from "views/auth/sign-up";
import UpdatePassword from "views/auth/update-passwrd";

// Admin Imports
import LinkedinProfile from "views/admin/linkedin-profile";
import DownloadExtension from "views/admin/download-extension";
import Setting from "views/admin/setting";
import Pricing from "views/admin/pricing";
import Billing from "views/admin/billing";
import History from "views/admin/history";

const routes: RoutesType[] = [
  {
    name: "Profile",
    heading: "Profile",
    layout: "",
    path: "/",
    icon: <Icon as={FaLinkedin} width="20px" height="20px" color="inherit" />,
    component: LinkedinProfile,
    exact: true,
  },
  {
    name: "History",
    heading: "History",
    layout: "",
    path: "/history",
    icon: <Icon as={FaBookOpen} width="20px" height="20px" color="inherit" />,
    component: History,
    exact: true,
  },
  {
    name: "Chrome Extension",
    heading: "Chrome Extension",
    layout: "",
    path: "/extension",
    icon: (
      <Icon
        as={BsFillArrowUpRightSquareFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: DownloadExtension,
    exact: true,
  },
  {
    name: "Pricing",
    layout: "",
    path: "/pricing",
    icon: (
      <Icon
        as={BsFillArrowUpRightSquareFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Pricing,
    disable: true,
  },
  {
    name: "Billing",
    layout: "",
    path: "/billing",
    icon: (
      <Icon
        as={BsFillArrowUpRightSquareFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Billing,
    disable: true,
  },
  {
    name: "Settings",
    layout: "",
    path: "/profile/:id/setting",
    icon: <Icon as={FaLinkedin} width="20px" height="20px" color="inherit" />,
    component: Setting,
    disable: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignIn,
    disable: true,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUp,
    disable: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
    disable: true,
  },
  {
    name: "Update Password",
    layout: "/auth",
    path: "/update-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: UpdatePassword,
    disable: true,
  },
];

export default routes;
