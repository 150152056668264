import { envConfig } from "config/env.config";
import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";
import { useGetUserQuery } from "views/auth/query/user.query";

function useChatSupport() {
  const { data: user } = useGetUserQuery();
  const isProd = envConfig.environment === "production";

  useEffect(() => {
    if (isProd) Crisp.configure(envConfig.crispApiKey);
  }, []);

  useEffect(() => {
    if (user?.email && isProd) {
      Crisp.user.setEmail(user.email);
      Crisp.user.setNickname(user?.firstName);
    }
  }, [user]);
}

export default useChatSupport;
