export const checkIsExtensionInstalled = async (
  extensionId: string,
  extensionIconUrl: string
): Promise<boolean> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = `chrome-extension://${extensionId}/${extensionIconUrl}`;
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
  });
};
