import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { tw } from "twind";

interface ISubscribedPlanCard {
  name: string;
  status: string;
  ctaButtonText: string;
  image: string;
  statusTextColor: string;
  lsPortalUrl: string;
  ctaUrlData: { internal: boolean; link: string };
}

function SubscribedPlanCard({
  image,
  name,
  ctaButtonText,
  status,
  statusTextColor,
  ctaUrlData,
  lsPortalUrl,
}: ISubscribedPlanCard) {
  const textColor = useColorModeValue("navy.700", "white");
  const history = useHistory();

  return (
    <Card p="20px" w="max-content">
      <Flex direction={{ base: "column" }} justify="center">
        <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
          <Image src={image} borderRadius="20px" maxW="350px" />
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize={{
                  base: "xl",
                  md: "lg",
                  lg: "lg",
                  xl: "lg",
                  "2xl": "md",
                  "3xl": "lg",
                }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {name}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize={{
                  base: "sm",
                }}
                fontWeight="400"
                me="14px"
              >
                Current Plan
              </Text>
            </Flex>
          </Flex>
          <Flex
            align={{
              base: "center",
              md: "start",
              lg: "center",
              xl: "start",
              "2xl": "center",
            }}
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mt="25px"
          >
            <Flex
              align="center"
              justifyContent="space-between"
              direction="row"
              w="100%"
            >
              <Text fontWeight="700" fontSize="lg" color={statusTextColor}>
                {status}
              </Text>
              {ctaUrlData?.internal ? (
                <Button
                  variant="darkBrand"
                  color="white"
                  fontSize="sm"
                  fontWeight="500"
                  borderRadius="70px"
                  px="24px"
                  py="5px"
                  onClick={() => history.push(ctaUrlData?.link)}
                >
                  {ctaButtonText}
                </Button>
              ) : (
                <Link
                  href={ctaUrlData?.link}
                  target="_blank"
                  className={tw(`w-full`)}
                >
                  <Button
                    variant="darkBrand"
                    color="white"
                    fontSize="sm"
                    fontWeight="500"
                    borderRadius="70px"
                    px="24px"
                    py="5px"
                  >
                    {ctaButtonText}
                  </Button>
                </Link>
              )}
            </Flex>
          </Flex>
          <Divider my={5} />
          <Flex
            align="center"
            justifyContent="space-between"
            direction="row"
            w="100%"
          >
            <Link href={lsPortalUrl} target="_blank" className={tw(`w-full!`)}>
              <Button
                variant="darkBrand"
                color="white"
                fontSize="sm"
                fontWeight="500"
                borderRadius="70px"
                px="24px"
                py="5px"
                w="100%"
              >
                Manage Subscription
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

export default SubscribedPlanCard;
