import {
  Box,
  Button,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import ReactHtmlParser from "react-html-parser";
import { FaCheckCircle } from "react-icons/fa";
import { IDisplayProduct } from "../interfaces/price.interface";
import PriceWrapper from "./PriceWrapper";

function PricingCell(
  props: IDisplayProduct & {
    onClick: () => void;
    disabled?: boolean;
    popular?: boolean;
    isSubmitLoading?: boolean;
  }
) {
  const featuresBg = useColorModeValue("gray.50", "gray.700");
  const popularBg = useColorModeValue("brand.100", "brand.300");
  const popularColor = useColorModeValue("gray.900", "gray.300");

  if (props?.popular) {
    return (
      <PriceWrapper>
        <Box position="relative">
          <Box
            position="absolute"
            top="-16px"
            left="50%"
            style={{ transform: "translate(-50%)" }}
          >
            <Text
              textTransform="uppercase"
              bg={popularBg}
              px={3}
              py={1}
              color={popularColor}
              fontSize="sm"
              fontWeight="600"
              rounded="xl"
            >
              Most Popular
            </Text>
          </Box>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              {props?.name}
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="5xl" fontWeight="900">
                {props?.variant?.displayPrice ?? props.defaultDisplayPrice}
              </Text>
            </HStack>
          </Box>
          <VStack bg={featuresBg} py={4} borderBottomRadius={"xl"}>
            <List spacing={3} textAlign="start" px={12}>
              {props?.features?.map((feat, idx) => (
                <ListItem key={idx}>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  {ReactHtmlParser(feat)}
                </ListItem>
              ))}
            </List>
            <Box w="80%" pt={7}>
              <Button
                w="full"
                colorScheme="brand"
                onClick={props?.onClick}
                disabled={props?.disabled}
                isLoading={props?.isSubmitLoading}
              >
                Select
              </Button>
            </Box>
          </VStack>
        </Box>
      </PriceWrapper>
    );
  }
  return (
    <PriceWrapper>
      <Box py={4} px={12}>
        <Text fontWeight="500" fontSize="2xl">
          {props?.name}
        </Text>
        <HStack justifyContent="center">
          <Text fontSize="5xl" fontWeight="900">
            {props?.variant?.displayPrice ?? props.defaultDisplayPrice}
          </Text>
        </HStack>
      </Box>
      <VStack bg={featuresBg} py={4} borderBottomRadius={"xl"}>
        <List spacing={3} textAlign="start" px={12}>
          {props.features?.map((feat, idx) => (
            <ListItem key={idx}>
              <ListIcon as={FaCheckCircle} color="green.500" />
              {ReactHtmlParser(feat)}
            </ListItem>
          ))}
        </List>
        <Box w="80%" pt={7}>
          <Button
            w="full"
            colorScheme="brand"
            onClick={props?.onClick}
            disabled={props?.disabled}
            isLoading={props?.isSubmitLoading}
          >
            Select
          </Button>
        </Box>
      </VStack>
    </PriceWrapper>
  );
}

export default PricingCell;
