import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useGetCompletedPostsQuery } from "./query/post.query";
import { useInView } from "react-intersection-observer";
import { useEffect, useMemo } from "react";
import { IPost } from "./interface/post.interface";
import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Link, Redirect } from "react-router-dom";
import { truncateText } from "utils/string.util";
import dayjs from "dayjs";

const columnHelper = createColumnHelper<IPost>();

function History() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const { ref, inView } = useInView();
  const {
    data: dataWithPagination,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetCompletedPostsQuery();

  const posts = useMemo(() => {
    const data: IPost[] = [];
    dataWithPagination?.pages?.forEach((p) => {
      p?.docs?.forEach((post) => data.push(post));
    });
    return data;
  }, [dataWithPagination?.pageParams]);

  const columns = [
    columnHelper.accessor("profile", {
      id: "profileName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          ACCOUNT
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            textTransform="capitalize"
          >
            {`${info.getValue().firstName} ${info.getValue()?.lastName}`}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("comment.content", {
      id: "comment",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          COMMENT
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            textTransform="capitalize"
          >
            {`${truncateText(info.getValue())}`}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("content", {
      id: "post",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          POST
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            textTransform="capitalize"
          >
            {`${truncateText(info.getValue())}`}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("activityUrn", {
      id: "urn",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          LINK
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            textDecoration="underline"
          >
            <a
              href={`https://linkedin.com/feed/update/${info.getValue()}`}
              target="_blank"
            >
              Link to the post
            </a>
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("comment.postedAt", {
      id: "postedAt",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          COMMENTED ON
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            textTransform="capitalize"
          >
            {`${dayjs(info.getValue()).format("MMM DD YYYY")}`}
          </Text>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: posts,
    columns,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <Box pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
      <Table variant="simple" color="gray.500" mb="24px" mt="12px">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: "",
                        desc: "",
                      }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Flex justifyContent="center">
        <Button
          ref={ref}
          variant="brand"
          fontWeight="500"
          fontSize="sm"
          minW="120px"
          disabled={!hasNextPage || isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "Nothing more to load"}
        </Button>
      </Flex>

      <></>
    </Box>
  );
}

export default History;
