import { axiosInstance, axiosInstanceWithoutToken } from "utils/axios.util";
import { IRedeemPayload, IRedemption } from "./redemption.interface";

export async function checkRedemptionCode(code: string) {
  const { data } = await axiosInstanceWithoutToken({
    method: "GET",
    url: `/redemption/check/${code}`,
  });
  return data as { isClaimed: boolean };
}

export async function claimRedemption(payload: IRedeemPayload) {
  const { data } = await axiosInstance({
    method: "POST",
    url: `/redemption/claim`,
    data: payload,
  });
  return data as IRedemption;
}
