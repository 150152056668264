import Cookies from "js-cookie";
import { useEffect } from "react";
import { AuthEnum } from "../enum/auth.enum";
import { SupabaseInstance } from "../services/supabase.service";
import { useAuthStore } from "../store/auth.store";

function useAuth() {
  const supabase = SupabaseInstance.getSupabase();
  const setSession = useAuthStore((state) => state.setSession);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        const { access_token, user, refresh_token, expires_in, expires_at } =
          session;
        const stringifiedSession = JSON.stringify({
          access_token,
          user,
          refresh_token,
          expires_in,
          expires_at,
        });
        Cookies.set(AuthEnum.AUTH_COOKIE_KEY, stringifiedSession);
        setSession(session);
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      Cookies.set(AuthEnum.AUTH_COOKIE_KEY, JSON.stringify(session));
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);
}

export default useAuth;
