import axios from "axios";
import { getAuthToken, signOut } from "views/auth/utils/auth.util";
import { envConfig } from "../config/env.config";

export const axiosInstance = axios.create({
  baseURL: envConfig.apiUrl,
});

export const axiosInstanceWithoutToken = axios.create({
  baseURL: envConfig.apiUrl,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (request) => {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${getAuthToken()}`;
    }
    return request;
  },
  (error) => Promise.reject(createError(error))
);

function createError(error: any) {
  if (axios.isCancel(error)) return error; // when we cancel a request internally

  const statusCode = error?.response.status;
  const statusText =
    error?.response?.data?.error?.message ||
    error?.response?.statusText ||
    error?.message ||
    "Something went wrong";

  if (statusCode === 403 || statusCode === 401) {
    signOut();
  }

  return { code: statusCode, message: statusText };
}
