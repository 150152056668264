import { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth-bg.png";
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { tw } from "twind";
import { signUpWithPassword } from "../utils/auth.util";
import { ISignUpForm } from "./types/form.type";
import RedemptionCheckProvider from "./components/RedemptionCheckProvider";

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [pwdShow, setPwdShow] = useState(false);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => setPwdShow(!pwdShow);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ISignUpForm>();

  async function onSubmit(data: ISignUpForm) {
    try {
      setIsLoading(true);
      const user = await signUpWithPassword(data);
      if (user?.id) {
        reset();
        setIsSignUpSuccess(true);
      } else {
        toast("Something went wrong. Please try again", { type: "error" });
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast(error?.message || "Something went wrong. Please try again", {
        type: "error",
      });
    }
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <RedemptionCheckProvider>
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Create an account
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter your email and password to sign up!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <Button
              fontSize="sm"
              me="0px"
              mb="26px"
              py="15px"
              h="50px"
              borderRadius="16px"
              bg={googleBg}
              color={googleText}
              fontWeight="500"
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
            >
              <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
              Sign up with Google
            </Button>
            <Flex align="center" mb="25px">
              <HSeparator />
              <Text color="gray.400" mx="14px">
                or
              </Text>
              <HSeparator />
            </Flex>
            {isSignUpSuccess ? (
              <div
                className={tw(
                  `p-4 mb-5 bg-green-500 rounded-xl text-white font-medium`
                )}
              >
                <h2 className={tw(`font-bold text-md mb-2`)}>
                  Confirm your email
                </h2>
                <p className={tw(`text-sm`)}>
                  Drumroll, please! 🥁 Confirm your email, and behold the
                  magical confirmation email in your inbox!" 📧✨
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex gap={3}>
                  <FormControl
                    isInvalid={Boolean(errors?.firstName?.message)}
                    mb="24px"
                  >
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      First Name<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      placeholder="John"
                      fontWeight="500"
                      size="lg"
                      {...register("firstName", {
                        required: "First Name required",
                      })}
                      disabled={isSignUpSuccess}
                    />
                    <FormErrorMessage>
                      {errors?.firstName?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(errors?.firstName?.message)}
                    mb="24px"
                  >
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Last Name<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      placeholder="Doe"
                      fontWeight="500"
                      size="lg"
                      {...register("lastName", {
                        required: "Last Name required",
                      })}
                      disabled={isSignUpSuccess}
                    />
                    <FormErrorMessage>
                      {errors?.lastName?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <FormControl
                  isInvalid={Boolean(errors?.email?.message)}
                  mb="24px"
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="hello@coldpitch.email"
                    fontWeight="500"
                    size="lg"
                    {...register("email", {
                      required: "Email required. No ninja moves allowed! 📧🥷",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Danger, Invalid email alert! 🤖📧",
                      },
                    })}
                    disabled={isSignUpSuccess}
                  />
                  <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={Boolean(errors?.password?.message)}
                  mb="24px"
                >
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      fontSize="sm"
                      placeholder="Enter password"
                      size="lg"
                      type={pwdShow ? "text" : "password"}
                      variant="auth"
                      {...register("password", {
                        required:
                          "Password required. No password, no entry! 🚧",
                      })}
                      disabled={isSignUpSuccess}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={pwdShow ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors?.password?.message}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  isLoading={isLoading}
                  loadingText="Signing up"
                >
                  Sign Up
                </Button>
              </form>
            )}

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                Have an account?
                <NavLink to="/auth/sign-in">
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    Sign In
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </RedemptionCheckProvider>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
