import { useEffect, useRef } from "react";

function useInitializeLemonSqueezy() {
  const isScriptLoaded = useRef<boolean>(false);

  const loadScript = async () => {
    if (!isScriptLoaded.current) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");

        script.src = "https://app.lemonsqueezy.com/js/lemon.js";
        script.async = true;

        script.onload = () => {
          isScriptLoaded.current = true;
          resolve(true);
        };

        script.onerror = () => {
          reject(new Error("Failed to load the script"));
        };

        document.body.appendChild(script);
      });
    }
  };

  const initializeLs = async () => {
    await loadScript();
    window.createLemonSqueezy();
  };

  useEffect(() => {
    initializeLs();
  }, []);
}

export default useInitializeLemonSqueezy;
