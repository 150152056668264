import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { checkRedemptionCode, claimRedemption } from "./redemption.api";

export enum RedemptionQueryEnum {
  CHECK_REDEMPTION_CODE = "check-redemption-code",
}

export const useCheckRedemptionCode = ({ code }: { code: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: [RedemptionQueryEnum.CHECK_REDEMPTION_CODE, code],
    queryFn: () => checkRedemptionCode(code),
    retry: 1,
  });
  return { data, isLoading };
};

export const useClaimRedemption = ({
  removeCode,
}: {
  removeCode: () => void;
}) => {
  const { mutate, isLoading } = useMutation(claimRedemption, {
    onSuccess: () => {
      removeCode();
    },
    onError: (error: any) => {
      removeCode();
      toast(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while claiming your redemption. Please contact support for assistance",
        {
          type: "error",
        }
      );
    },
  });

  return { claim: mutate, isClaiming: isLoading };
};
