import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { UserSubscriptionStatus } from "views/auth/interfaces/user.interface";
import { useGetUserQuery } from "views/auth/query/user.query";
import { SubscriptionStatusEnum } from "../subscription/enum/subscription.enum";
import { useGetCustomerPortalUrlQuery } from "../subscription/query/subscription.query";
import SubscribedPlanCard from "./components/SubscribedPlanCard";
import {
  subscriptionStatusColorMapper,
  subscriptionStatusTextMapper,
} from "./constants/subscription.constant";

function Billing() {
  const { data: user } = useGetUserQuery();
  const { data: customerPortalUrl } = useGetCustomerPortalUrlQuery({ user });

  const subscriptionStatusText = useMemo(() => {
    let displayStatus: string;
    let status: UserSubscriptionStatus | SubscriptionStatusEnum;
    if (user?.status === UserSubscriptionStatus.INACTIVE) {
      displayStatus = subscriptionStatusTextMapper[user?.subscription?.status];
      status = user?.subscription?.status;
    } else {
      displayStatus = subscriptionStatusTextMapper[user?.status];
      status = user?.status;
    }
    return { displayStatus, status };
  }, [user]);

  const subscriptionStatusTextColor = useMemo(() => {
    const { status } = subscriptionStatusText;
    return subscriptionStatusColorMapper[status];
  }, [subscriptionStatusText]);

  const ctaButton = useMemo(() => {
    if (subscriptionStatusText?.status === UserSubscriptionStatus.ACTIVE)
      return { text: "Change Plan", url: "/pricing", internal: true };
    else if (
      subscriptionStatusText?.status === UserSubscriptionStatus.IN_TRIAL ||
      subscriptionStatusText?.status === UserSubscriptionStatus.TRIAL_EXPIRED
    )
      return { text: "Upgrade Now", url: "/pricing", internal: true };
    else if (
      subscriptionStatusText?.status === SubscriptionStatusEnum.PAST_DUE ||
      subscriptionStatusText?.status === SubscriptionStatusEnum.UNPAID
    )
      return {
        text: "Update Payment Method",
        url: customerPortalUrl?.updatePaymentMethod,
        internal: false,
      };
    else if (subscriptionStatusText?.status === SubscriptionStatusEnum.PAUSED)
      return {
        text: "Resume Plan",
        url: customerPortalUrl?.customerPortal,
        internal: false,
      };
    else if (
      subscriptionStatusText?.status === SubscriptionStatusEnum.CANCELLED ||
      subscriptionStatusText?.status === SubscriptionStatusEnum.EXPIRED
    )
      return { text: "Renew Subscription", url: "/pricing", internal: true };

    return { text: "Upgrade", url: "/pricing", internal: true };
  }, [subscriptionStatusText?.status, customerPortalUrl]);

  return (
    <Box py={12} pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
      <Alert status="warning" mb={5}>
        <AlertIcon />
        <AlertTitle>Subscription Changes</AlertTitle>
        <AlertDescription>
          If you've updated or created a subscription, it may take some time to
          reflect. Contact support if it's still not updated.
        </AlertDescription>
      </Alert>

      <SubscribedPlanCard
        ctaButtonText={ctaButton.text}
        image={user?.subscribedProduct?.imageUrl}
        name={user?.subscribedProduct?.name}
        status={subscriptionStatusText?.displayStatus as string}
        statusTextColor={subscriptionStatusTextColor}
        lsPortalUrl={customerPortalUrl?.customerPortal}
        ctaUrlData={{ internal: ctaButton?.internal, link: ctaButton?.url }}
      />
    </Box>
  );
}

export default Billing;
