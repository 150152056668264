import { ChakraProvider } from "@chakra-ui/react";
import AuthProvider from "providers/AuthProvider";
import React from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryService } from "services/query.service";
import useAuth from "views/auth/hook/useAuth";
import "./assets/css/App.css";
import theme from "./theme/theme";

function Renderer() {
  const queryClient = QueryService.getQueryClient();

  // Auth hooks
  useAuth();

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <BrowserRouter>
            <Switch>
              <Route path={`/`} component={AuthProvider} />
              {/* <Redirect from="/" to="/admin" /> */}
            </Switch>
          </BrowserRouter>
        </React.StrictMode>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default Renderer;
