import { axiosInstance } from "utils/axios.util";
import { IPost } from "../interface/post.interface";

export async function getCompletedPosts(pageParam: number, limit = 50) {
  const { data } = await axiosInstance({
    method: "GET",
    url: `/post/completed?page=${pageParam}&limit=${limit}`,
  });
  return data as {
    docs: IPost[];
    prevPage: number | null;
    nextPage: number | null;
  };
}
