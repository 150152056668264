import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { QueryService } from "services/query.service";
import { IUser } from "views/auth/interfaces/user.interface";
import { UserQueryEnum } from "views/auth/query/user.query";
import {
  createCheckoutUrl,
  getCustomerPortalUrl,
  upgradeDowngradeSubscription,
} from "../api/subscription.api";

export enum SubscriptionQueryEnum {
  GET_CUSTOMER_PORTAL_URL = "get-customer-portal-url",
}

export const useUpdateSubscriptionPlan = ({ history }: { history: any }) => {
  const queryClient = QueryService.getQueryClient();
  const { mutate, isLoading } = useMutation(upgradeDowngradeSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries(UserQueryEnum.GET_USER);
      history.push(`/billing`);
    },
    onError: (error: any) => {
      toast(
        error.message ||
          "Something went wrong while updating subscription plan. Please contact support.",
        {
          type: "error",
        }
      );
    },
  });

  return {
    updateSubscriptionPlan: mutate,
    isUpdatingSubscriptionPlan: isLoading,
  };
};

export const useCreateCheckoutUrl = ({ cb }: { cb: (url: string) => void }) => {
  const { mutate, isLoading } = useMutation(createCheckoutUrl, {
    onSuccess: (data) => {
      if (data?.url) cb(data.url);
      else
        toast("Couldn't get the checkout url", {
          type: "error",
        });
    },
    onError: (error: any) => {
      toast(
        error.message ||
          "Something went wrong while checking out. Please contact support",
        {
          type: "error",
        }
      );
    },
  });

  return {
    createCheckoutUrl: mutate,
    isCreatingCheckoutUrl: isLoading,
  };
};

export const useGetCustomerPortalUrlQuery = ({ user }: { user: IUser }) => {
  console.log({ user });

  const placeholderData = {
    customerPortal: "",
    customerPortalUpdateSubscription: "",
    updatePaymentMethod: "",
  };
  const { data, isLoading } = useQuery({
    queryKey: SubscriptionQueryEnum.GET_CUSTOMER_PORTAL_URL,
    queryFn: () =>
      user?.subscription ? getCustomerPortalUrl() : placeholderData,
    placeholderData,
    retry: 2,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 1000 * 60 * 60 * 24,
    onError: () => {
      toast("Failed to fetch customer portal urls", { type: "error" });
    },
  });
  return { data, isLoading };
};
